import React from 'react';
import { Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import Text from '../shared/Text';
import ScreenWrapper from '../shared/ScreenWrapper';
import HeroImage from '../shared/HeroImage';
import TitledImageCard from '../shared/TiltedImageCard';
import FeatureTableRow from '../shared/FeatureTableRow';
import StoreButton from '../shared/StoreButton';
import {
    controls_background,
    controls_background_small,
    map_background,
    track_background,
    map_background_small,
    track_background_small,
    DashLogo,
    LargeStatsLogo,
    MapsLogo,
    CommunityLogo,
    ControlsLogo,
    AutomationLogo,
    LiveTrackLogo,
    tilted_mini_controls,
    battery_performance,
    range_added_used,
    carbon_impact,
    power_mix,
    locations,
    summary,
    charging_costs,
    supercharger_screen,
    connect_friends,
    groups,
    current_drive,
    current_charge,
    eff_vs_others,
    apple_watch,
    community_background,
    community_background_small,
    dash_background,
    dash_background_small,
    live_data_background,
    live_data_background_small,
    group_stats,
    share_content,
    zaps,
    widgets,
    all_tracked_events,
    automation_background,
    automation_background_small,
    tilted_status,
    tilted_dash_widgets,
    tilted_activity,
    tilted_usage_report,
    tilted_idle_summary,
    tilted_idle_detail
} from '../../utility/icons';
import styles from '../../utility/styles';

const HeroContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 300px;
    @media only screen and ${styles.breakpoints.mobileL} {
        align-items: center;
        text-align: center;
    }
`;

const LogoContainer = styled.div`
    margin-bottom: 20px;
    @media only screen and ${styles.breakpoints.mobileL} {
        padding: 16px;
        border-radius: 32px;
        background-color: ${styles.colors.darkGray};
    }
`;

const CenteredDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledDashLogo = styled(DashLogo)`
    @media only screen and ${styles.breakpoints.massive} {
        transform: scale(2) translateX(25%);
    }
`;

const StyledLargeStatsLogo = styled(LargeStatsLogo)`
    @media only screen and ${styles.breakpoints.massive} {
            transform: scale(2) translateX(25%);
        }
`;

const StyledMapsLogo = styled(MapsLogo)`
    @media only screen and ${styles.breakpoints.massive} {
            transform: scale(2) translateX(25%);
        }
`;

const StyledCommunityLogo = styled(CommunityLogo)`
    @media only screen and ${styles.breakpoints.massive} {
            transform: scale(2) translateX(25%);
        }
`;

const StyledLiveTrackLogo = styled(LiveTrackLogo)`
    @media only screen and ${styles.breakpoints.massive} {
            transform: scale(2) translateX(25%);
        }
`;

const StyledAutomationLogo = styled(AutomationLogo)`
    @media only screen and ${styles.breakpoints.massive} {
            transform: scale(2) translateX(25%);
        }
`;

const StyledControlsLogo = styled(ControlsLogo)`
    @media only screen and ${styles.breakpoints.massive} {
        transform: scale(2) translateX(25%);
    }
`;



const ratios = {
    large: 1024 / 580,
    small: 375 / 500
}

export default function App({ pricing = {} }) {
    return (
        <ScreenWrapper style={{ position: 'relative' }}>
            <HeroImage
                srcs={{ large: dash_background, small: dash_background_small }}
                {...{ ratios }}
                containerStyle={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                }}
                style={{ marginBottom: '72px' }}
                offset={72}
                rounded='0 0 24px 24px'
                left
            >
                <HeroContainer>
                    <LogoContainer>
                        <StyledDashLogo />
                    </LogoContainer>
                    <Text.Heading variant='bold' type='h1' mobileL='h3'>The Dash</Text.Heading>
                    <Text.Body>The dash is your home for everything about your car.  Quick controls, stats and everything charging.</Text.Body>
                </HeroContainer>
            </HeroImage>
            <Container>
                <Row className='d-flex justify-content-center'>
                    <TitledImageCard
                        src={tilted_status}
                        colProps={{
                            lg: 4,
                            md: 6,
                            sm: 6,
                            xs: 12
                        }}
                        title='Battery and Car Status'
                        body="Get your car's current state, battery level, and real world range at a glance"
                    />
                    <TitledImageCard
                        src={tilted_mini_controls}
                        colProps={{
                            lg: 4,
                            md: 6,
                            sm: 6,
                            xs: 12
                        }}
                        title='Quick Controls'
                        body='Immediate access from your dashboard to your favorite set of controls.'
                    />
                    <TitledImageCard
                        src={tilted_dash_widgets}
                        colProps={{
                            lg: 4,
                            md: 6,
                            sm: 6,
                            xs: 12
                        }}
                        title='Dash Widgets'
                        body='At a glance, a roll-up of everything about your car at your finger tips.'
                    />
                    <TitledImageCard
                        src={tilted_activity}
                        colProps={{
                            lg: 4,
                            md: 6,
                            sm: 6,
                            xs: 12
                        }}
                        title='Recent Activity'
                        body='Seamlessly view recent drives and charges to gain insights on trends'
                    />
                    <TitledImageCard
                        src={battery_performance}
                        colProps={{
                            lg: 4,
                            md: 6,
                            sm: 6,
                            xs: 12
                        }}
                        title='Battery Performance'
                        body="An overview of your battery's maximum range based on your past charges"
                    />
                    <TitledImageCard
                        src={range_added_used}
                        colProps={{
                            lg: 4,
                            md: 6,
                            sm: 6,
                            xs: 12
                        }}
                        title='Range Added vs. Used'
                        body='Review trends over time as you charge and drive.'
                    />
                </Row>
            </Container>
            <HeroImage
                srcs={{ large: community_background, small: community_background_small }}
                {...{ ratios }}
                containerStyle={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-end'
                }}
                style={{ marginBottom: '72px' }}
                offset={72}
                rounded='24px 24px 24px 24px'
                left={false}
            >
                <HeroContainer>
                    <LogoContainer>
                        <StyledCommunityLogo />
                    </LogoContainer>
                    <Text.Heading goBig variant='bold' type='h1' mobileL='h3'>Community</Text.Heading>
                    <Text.Body goBig>Create public and private groups and share drives, zaps, and charging locations with friends.</Text.Body>
                </HeroContainer>
            </HeroImage>
            <Container>
                <Row>
                    <TitledImageCard
                        src={share_content}
                        colProps={{
                            lg: 4,
                            md: 6,
                            sm: 6,
                            xs: 12
                        }}
                        title='Share Content'
                        body='Have an interesting drive or charge? Share it with the community!'
                    />
                    <TitledImageCard
                        src={connect_friends}
                        colProps={{
                            lg: 4,
                            md: 6,
                            sm: 6,
                            xs: 12
                        }}
                        title='Connect with Friends'
                        body='Connect with friends and share the EV experience'
                    />
                    <TitledImageCard
                        src={groups}
                        colProps={{
                            lg: 4,
                            md: 6,
                            sm: 6,
                            xs: 12
                        }}
                        title='Groups'
                        body='Join groups to engage with others who are embracing the EV lifestyle'
                    />
                </Row>
            </Container>
            <HeroImage
                srcs={{ large: map_background, small: map_background_small }}
                {...{ ratios }}
                containerStyle={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                }}
                style={{ marginBottom: '72px' }}
                offset={72}
                rounded='24px 24px 24px 24px'
                left
            >
                <HeroContainer>
                    <LogoContainer>
                        <StyledMapsLogo />
                    </LogoContainer>
                    <Text.Heading goBig variant='bold' type='h1' mobileL='h3'>Map</Text.Heading>
                    <Text.Body goBig>Browse our best in class supercharger map right from your phone - we've added some fun games and additional information to assist during your next road trip.</Text.Body>
                </HeroContainer>
            </HeroImage>
            <Container>
                <Row>
                    <TitledImageCard
                        src={supercharger_screen}
                        colProps={{
                            md: 6,
                            sm: 6,
                            xs: 12,
                            style: {
                                justifyContent: 'flex-end'
                            }
                        }}
                        title='Supercharger Pages'
                        body='Get a clear picture of what any supercharger has to offer'
                    />
                    <TitledImageCard
                        src={zaps}
                        colProps={{
                            md: 6,
                            sm: 6,
                            xs: 12,
                            style: {
                                justifyContent: 'flex-start'
                            }
                        }}
                        title='Zaps'
                        body='Video reviews of supercharger locations that help you plan your trip'
                    />
                </Row>
            </Container>
            <HeroImage
                srcs={{ large: track_background, small: track_background_small }}
                {...{ ratios }}
                containerStyle={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-end'
                }}
                style={{ marginBottom: '72px' }}
                offset={72}
                rounded='24px 24px 24px 24px'
                left={false}
            >
                <HeroContainer>
                    <LogoContainer>
                        <StyledLargeStatsLogo />
                    </LogoContainer>
                    <Text.Heading goBig variant='bold' type='h1' mobileL='h3'>Explore, Tag and Learn</Text.Heading>
                    <Text.Body goBig>Review usage reports, charges, charge locations, and drives. Tag your drives to keep them organized.</Text.Body>
                </HeroContainer>
            </HeroImage>
            <Container>
                <Row>
                    <TitledImageCard
                        src={tilted_usage_report}
                        colProps={{
                            lg: 4,
                            md: 6,
                            sm: 6,
                            xs: 12
                        }}
                        title='Usage Reports'
                        body='Usage reports are helpful in providing a snapshot view of how you use your battery'
                    />
                    <TitledImageCard
                        src={tilted_idle_summary}
                        colProps={{
                            lg: 4,
                            md: 6,
                            sm: 6,
                            xs: 12
                        }}
                        title='Idle Overview'
                        body='Peak under the hood to see what your battery is doing while idle'
                    />
                    <TitledImageCard
                        src={tilted_idle_detail}
                        colProps={{
                            lg: 4,
                            md: 6,
                            sm: 6,
                            xs: 12
                        }}
                        title='Detailed Idle Usage'
                        body='See a detailed view of each idle period'
                    />
                    <TitledImageCard
                        src={all_tracked_events}
                        colProps={{
                            lg: 4,
                            md: 6,
                            sm: 6,
                            xs: 12
                        }}
                        title='All Tracked Events'
                        body='Get a holistic view of all charges and drives in timeline order'
                    />
                    <TitledImageCard
                        src={carbon_impact}
                        colProps={{
                            lg: 4,
                            md: 6,
                            sm: 6,
                            xs: 12
                        }}
                        title='Carbon Impact'
                        body='Understand the carbon impact of the electricity you use to charge'
                    />
                    <TitledImageCard
                        src={power_mix}
                        colProps={{
                            lg: 4,
                            md: 6,
                            sm: 6,
                            xs: 12
                        }}
                        title='Power Mix'
                        body='Understand where the energy comes from that powers your car'
                    />
                    <TitledImageCard
                        src={locations}
                        colProps={{
                            lg: 4,
                            md: 6,
                            sm: 6,
                            xs: 12
                        }}
                        title='Locations'
                        body='Charging costs and stats organized by charging location'
                    />
                    <TitledImageCard
                        src={summary}
                        colProps={{
                            lg: 4,
                            md: 6,
                            sm: 6,
                            xs: 12
                        }}
                        title='Summary'
                        body='Refine with filters and view rolled up summaries'
                    />
                    <TitledImageCard
                        src={charging_costs}
                        colProps={{
                            lg: 4,
                            md: 6,
                            sm: 6,
                            xs: 12
                        }}
                        title='Charge Cost Tracking'
                        body='Track the cost of charging at any charger'
                    />
                </Row>
            </Container>
            <HeroImage
                srcs={{ large: live_data_background, small: live_data_background_small }}
                {...{ ratios }}
                containerStyle={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                }}
                style={{ marginBottom: '72px' }}
                offset={72}
                rounded='24px 24px 24px 24px'
                left
            >
                <HeroContainer>
                    <LogoContainer>
                        <StyledLiveTrackLogo />
                    </LogoContainer>
                    <Text.Heading goBig variant='bold' type='h1' mobileL='h3'>Live Data</Text.Heading>
                    <Text.Body goBig>We tell you how far you can actually drive and give you valuable insights while you charge.  Drive and charge smarter with Live Drive.</Text.Body>
                </HeroContainer>
            </HeroImage>
            <Container>
                <Row>
                    <TitledImageCard
                        src={current_drive}
                        colProps={{
                            md: 6,
                            sm: 6,
                            xs: 12,
                            style: {
                                justifyContent: 'flex-end'
                            }
                        }}
                        title='Current Drive'
                        body='Understand the impact of your driving style on your range during your drive'
                    />
                    <TitledImageCard
                        src={current_charge}
                        colProps={{
                            md: 6,
                            sm: 6,
                            xs: 12,
                            style: {
                                justifyContent: 'flex-start'
                            }
                        }}
                        title='Current Charge'
                        body='Get detailed charging information and projections at a glance'
                    />
                </Row>
            </Container>
            <HeroImage
                srcs={{ large: automation_background, small: automation_background_small }}
                {...{ ratios }}
                containerStyle={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-end'
                }}
                style={{ marginBottom: '72px' }}
                offset={72}
                rounded='24px 24px 24px 24px'
                left={false}
            >
                <HeroContainer>
                    <LogoContainer>
                        <StyledAutomationLogo />
                    </LogoContainer>
                    <Text.Heading goBig allowWidows variant='bold' type='h1' mobileL='h3'>Automation = Auto&#8209;MAZING</Text.Heading>
                    <Text.Body goBig>From location based plugin reminders to scheduling your climate while on the go.</Text.Body>
                </HeroContainer>
            </HeroImage>
            <Container>
                <Row>
                    <TitledImageCard
                        src={eff_vs_others}
                        colProps={{
                            md: 6,
                            sm: 6,
                            xs: 12
                        }}
                        title='Efficiency vs. Others'
                        body='Understand how your usage of your car compares to drivers in your area'
                    />
                    <TitledImageCard
                        src={group_stats}
                        colProps={{
                            md: 6,
                            sm: 6,
                            xs: 12
                        }}
                        title='Group Stats'
                        body='See how all of your groups compare'
                    />
                </Row>
            </Container>
            <HeroImage
                srcs={{ large: controls_background, small: controls_background_small }}
                {...{ ratios }}
                containerStyle={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                }}
                style={{ marginBottom: '72px' }}
                offset={72}
                rounded='24px 24px 24px 24px'
                left
            >
                <HeroContainer>
                    <LogoContainer>
                        <StyledControlsLogo />
                    </LogoContainer>
                    <Text.Heading goBig variant='bold' type='h1' mobileL='h3'>Full Suite of Car Controls</Text.Heading>
                    <Text.Body goBig>Complete control of your car on the go from your phone and Apple Watch</Text.Body>
                </HeroContainer>
            </HeroImage>
            <Container>
                <Row>
                    <TitledImageCard
                        src={widgets}
                        colProps={{
                            md: 6,
                            sm: 6,
                            xs: 12,
                            style: {
                                justifyContent: 'flex-end'
                            }
                        }}
                        minWidth={440}
                        title='Powerful Widgets'
                        body='Sometimes a simple glace at the homescreen is enough '
                    />
                    <TitledImageCard
                        src={apple_watch}
                        colProps={{
                            md: 6,
                            sm: 6,
                            xs: 12,
                            style: {
                                justifyContent: 'flex-start'
                            }
                        }}
                        minWidth={440}
                        title='Apple Watch Compatible'
                        body="View your car's status and control it from your wrist"
                    />
                </Row>
            </Container>
            <Text.Heading variant='bold' type='h2' style={{ margin: '0 24px 4px 24px', textAlign: 'center' }} id='pricing' mobileL='h3'>Plans and Pricing</Text.Heading>
            <Text.Heading type='h5' mobileL='caption' style={{ margin: '0 24px 56px 24px', textAlign: 'center' }}>All first time subscriptions start with a <span style={{ fontWeight: 800 }}>full access 14-day free trial of TezLab Pro.</span></Text.Heading>
            <Container fluid style={{ marginBottom: '40px', maxWidth: '880px' }}>
                <FeatureTableRow.Heading />
                <FeatureTableRow.Body
                    freeType='free'
                    text='Create and participate in groups'
                />
                <FeatureTableRow.Body
                    freeType='free'
                    text='Customize your dashboard with your favorite widgets'
                />
                <FeatureTableRow.Body
                    freeType='free'
                    text='Plan better trips with our supercharger map'
                />
                <FeatureTableRow.Body
                    freeType='free'
                    text='Plant trees and become carbon neutral'
                />
                <FeatureTableRow.Body
                    freeType='free'
                    text='Current drives live data'
                />
                <FeatureTableRow.Body
                    freeType='free'
                    text='Current charge power and range forcasts'
                />
                <FeatureTableRow.Body
                    freeType='free'
                    text='Car controls'
                />
                <FeatureTableRow.Body
                    freeType='free'
                    text='Carbon impact report for charges'
                />
                <FeatureTableRow.Body
                    freeType='free'
                    text='Power mix reports'
                />
                <FeatureTableRow.Body
                    freeType='7-day'
                    text='Unlimited tracking of drives and charges'
                />
                <FeatureTableRow.Body
                    freeType='7-day'
                    text='Efficiency comparison to others in your area'
                />
                <FeatureTableRow.Body
                    freeType='limited'
                    text='Comprehensive view of how you charge and use your battery'
                />
                 <FeatureTableRow.Body
                    freeType='none'
                    text='Pro Usage Report with Detailed Idle Analysis'
                />
                <FeatureTableRow.Body
                    freeType='none'
                    text='Road Trips'
                />
                <FeatureTableRow.Body
                    freeType='none'
                    text='Map Range Rings'
                />
                <FeatureTableRow.Body
                    freeType='none'
                    text='Delayed/Scheduled Climate Start'
                />
                <FeatureTableRow.Body
                    freeType='none'
                    text='Location Based Alerts (i.e. Plug In Reminders)'
                />
                {/* <FeatureTableRow.Body
                    freeType='none'
                    text='Track multiple vehicles'
                /> */}
                <FeatureTableRow.Body
                    freeType='none'
                    text='Enhanced polling for improved data detail'
                />
                <FeatureTableRow.Body
                    freeType='none'
                    text='TezLab Apple Watch App (for Tesla)'
                />
                <FeatureTableRow.Body
                    freeType='none'
                    text='Data Export to CSV'
                />
                <FeatureTableRow.Footer {...pricing} />
            </Container>
            <Text.Heading type='h2' variant='bold' style={{ textAlign: 'center', margin: '0 56px 16px 56px' }} mobileL='h3'>Download today and start exploring the EV lifestyle.</Text.Heading>
            <CenteredDiv style={{ marginBottom: '56px' }}>
                <StoreButton type='apple' />
                <StoreButton type='android' />
            </CenteredDiv>
        </ScreenWrapper>
    )
}